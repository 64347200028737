
import { Component, Vue, Prop } from "nuxt-property-decorator";
import {
  BIcon,
  BIconChevronDown,
  BIconChevronLeft,
  BIconArrowLeftCircle,
  BIconArrowDownCircle,
  BIconArrowDownCircleFill,
} from "bootstrap-vue";
import { Item } from "@/types/responses/inserzione/ResponseInserzioneInterface";
import { Storico, StoricoAsteResponse } from "@/types/responses/inserzione/StoricoAste";

@Component({
  components: {
    BIcon,
    BIconChevronDown,
    BIconChevronLeft,
    BIconArrowLeftCircle,
    BIconArrowDownCircle,
    BIconArrowDownCircleFill,
  },
})
export default class TheTabs extends Vue {
  @Prop() item!: Item;
  public opened: boolean = false;
  public loadMapLazly: boolean = false;
  public storicoAste: Array<Storico> = [];

  public info_venditaVisible: boolean = true;
  @Prop() dettaglioLottoVisible!: boolean;
  public pvpVisible: boolean = true;
  public modalita_partecipazioneVisible: boolean = true;
  public storicoVisible: boolean = false;

  public toggleLoadMapLazlyAndDettaglioLotto() {
    this.loadMapLazly = !this.loadMapLazly;
    this.$emit("change-dettaglio-lotto-visible", !this.dettaglioLottoVisible);
  }

  mounted() {
    this.$emit("change-dettaglio-lotto-visible", false);
    this.pvpVisible = false;
    this.modalita_partecipazioneVisible = false;

    // chiamo API per verificare se esiste storico aste
    this.hasPriceHistory();
  }

  async hasPriceHistory() {
    try {
      const { data } = await this.$axios.$get<StoricoAsteResponse>(
        this.$config.astaEndpoint + "inserzioni/storico-inserzione/" + this.item.id
      );
      if (data?.length) {
        this.storicoAste = data;
      }
    } catch (e) {
      if (e instanceof Error) {
        console.error(e);
      }
      throw e;
    }
  }
}
