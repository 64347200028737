
import { Component, Vue, Prop } from "nuxt-property-decorator";
import { Loader } from "@googlemaps/js-api-loader";
import { ClientMap } from "~/types/responses/inserzione/ResponseInserzioneInterface";

@Component
export default class TheMapIvg extends Vue {
  public componentLoader: any | null = null;
  @Prop() dataMap!: any;
  @Prop() clientMap!: ClientMap;

  public mapOptions = {
    center: {
      lat: this.dataMap.lat,
      lng: this.dataMap.lng,
    },
    zoom: 14,
    styles: require("assets/map/mapstyle.json"),
    styledata: "roadmap",
    disableDefaultUI: true,
  };

  mounted() {
    const points = [
      {
        position: {
          lat: this.dataMap.lat,
          lng: this.dataMap.lng,
        },
      },
    ];
    this.initMap(points);
  }

  // (fix Expected mapDiv of type HTMLElement but was passed undefined)
  beforeDestroy() {
    this.componentLoader = null;
  }

  public async initMap(points: any[] = []) {
    this.componentLoader = await this.mapComponentLoader();
    const google = await this.componentLoader.load();
    const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker");
    const { Map } = await google.maps.importLibrary("maps");

    const map = new Map(this.$refs.googleMap, {
      center: {
        lat: 42.152636,
        lng: 12.345637,
      },
      zoom: 6,
      mapId: process.env.GOOGLE_MAPS_ID,
    });

    for (let i = 0; i < points.length; i++) {
      const pin = new PinElement({
        background: "#0052cc",
        borderColor: "#0061f2",
        glyphColor: "white",
      });

      const marker = new AdvancedMarkerElement({
        map,
        position: points[i].position,
        content: pin.element,
      });

      google.maps.event.addListener(marker, "click", () => {
        if (map) {
          map.panTo(marker.position);
        }
      });
    }
  }

  public mapComponentLoader() {
    if (this.componentLoader) {
      return this.componentLoader;
    }
    return new Loader({
      apiKey: this.clientMap.key,
      version: "beta",
      language: "it",
      libraries: ["places", "marker"],
    });
  }
}
