
import { Component, Vue, Prop } from "nuxt-property-decorator";
import {
  BIcon,
  BIconColumns,
  BIconMegaphone,
  BIconShieldCheck,
  BIconShieldExclamation,
  BIconRulers,
  BIconArrowLeftCircle,
  BIconArrowDownCircle,
  BIconArrowDownCircleFill,
} from "bootstrap-vue";

@Component({
  components: {
    BIcon,
    BIconColumns,
    BIconMegaphone,
    BIconShieldCheck,
    BIconShieldExclamation,
    BIconRulers,
    BIconArrowLeftCircle,
    BIconArrowDownCircle,
    BIconArrowDownCircleFill,
  },
})
export default class TheDocuments extends Vue {
  @Prop() item!: any;

  seeOtherDocs() {
    this.$emit("change-dettaglio-lotto-visible", true);
    setTimeout(() => {
      const otherDocsLotto = document.getElementById("other-docs-lotto");
      if (otherDocsLotto) {
        otherDocsLotto.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        return;
      }
      const otherDocsBeni = document.getElementById("other-docs-beni-0");
      if (otherDocsBeni) {
        otherDocsBeni.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        return;
      }
    }, 425);
  }
  otherDocsExists() {
    return (
      parseInt(this.item.tabs.lotto?.groups?.beni?.fields?.[0]?.documenti?.length ?? 0) +
      parseInt(this.item.tabs.lotto?.groups?.lotto?.fields?.[0]?.documenti?.length ?? 0) +
      parseInt(this.item.tabs.lotto?.groups?.beni?.fields?.documenti?.length ?? 0) +
      parseInt(this.item.tabs.lotto?.groups?.lotto?.fields?.documenti?.length ?? 0)
    );
  }
}
