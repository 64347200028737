export const AuthLoginModal = () => import('../../components/auth/LoginModal.vue' /* webpackChunkName: "components/auth-login-modal" */).then(c => wrapFunctional(c.default || c))
export const AuthNewPassword = () => import('../../components/auth/NewPassword.vue' /* webpackChunkName: "components/auth-new-password" */).then(c => wrapFunctional(c.default || c))
export const AuthRequestNewPassword = () => import('../../components/auth/RequestNewPassword.vue' /* webpackChunkName: "components/auth-request-new-password" */).then(c => wrapFunctional(c.default || c))
export const AuthSignIn = () => import('../../components/auth/SignIn.vue' /* webpackChunkName: "components/auth-sign-in" */).then(c => wrapFunctional(c.default || c))
export const AuthSignInReserved = () => import('../../components/auth/SignInReserved.vue' /* webpackChunkName: "components/auth-sign-in-reserved" */).then(c => wrapFunctional(c.default || c))
export const AuthSignUp = () => import('../../components/auth/SignUp.vue' /* webpackChunkName: "components/auth-sign-up" */).then(c => wrapFunctional(c.default || c))
export const AuthTheLoginSlot = () => import('../../components/auth/TheLoginSlot.vue' /* webpackChunkName: "components/auth-the-login-slot" */).then(c => wrapFunctional(c.default || c))
export const AuthTheWrapper = () => import('../../components/auth/TheWrapper.vue' /* webpackChunkName: "components/auth-the-wrapper" */).then(c => wrapFunctional(c.default || c))
export const ChiSiamoTheMap = () => import('../../components/chi-siamo/TheMap.vue' /* webpackChunkName: "components/chi-siamo-the-map" */).then(c => wrapFunctional(c.default || c))
export const ChiSiamoTheMapIvg = () => import('../../components/chi-siamo/TheMapIvg.vue' /* webpackChunkName: "components/chi-siamo-the-map-ivg" */).then(c => wrapFunctional(c.default || c))
export const CoreSearchWrapper = () => import('../../components/core/SearchWrapper.vue' /* webpackChunkName: "components/core-search-wrapper" */).then(c => wrapFunctional(c.default || c))
export const CoreSectionSearch = () => import('../../components/core/SectionSearch.vue' /* webpackChunkName: "components/core-section-search" */).then(c => wrapFunctional(c.default || c))
export const ElementsBookingSpecial = () => import('../../components/elements/BookingSpecial.vue' /* webpackChunkName: "components/elements-booking-special" */).then(c => wrapFunctional(c.default || c))
export const ElementsFormResponse = () => import('../../components/elements/FormResponse.vue' /* webpackChunkName: "components/elements-form-response" */).then(c => wrapFunctional(c.default || c))
export const ElementsInputWithValidation = () => import('../../components/elements/InputWithValidation.vue' /* webpackChunkName: "components/elements-input-with-validation" */).then(c => wrapFunctional(c.default || c))
export const ElementsModalShare = () => import('../../components/elements/ModalShare.vue' /* webpackChunkName: "components/elements-modal-share" */).then(c => wrapFunctional(c.default || c))
export const ElementsMyLightBox = () => import('../../components/elements/MyLightBox.vue' /* webpackChunkName: "components/elements-my-light-box" */).then(c => wrapFunctional(c.default || c))
export const ElementsNewsletterForm = () => import('../../components/elements/NewsletterForm.vue' /* webpackChunkName: "components/elements-newsletter-form" */).then(c => wrapFunctional(c.default || c))
export const ElementsPrivacyCheckbox = () => import('../../components/elements/PrivacyCheckbox.vue' /* webpackChunkName: "components/elements-privacy-checkbox" */).then(c => wrapFunctional(c.default || c))
export const ElementsSegnalazioniForm = () => import('../../components/elements/SegnalazioniForm.vue' /* webpackChunkName: "components/elements-segnalazioni-form" */).then(c => wrapFunctional(c.default || c))
export const ElementsTheBackToTop = () => import('../../components/elements/TheBackToTop.vue' /* webpackChunkName: "components/elements-the-back-to-top" */).then(c => wrapFunctional(c.default || c))
export const ElementsWorkWithUsForm = () => import('../../components/elements/WorkWithUsForm.vue' /* webpackChunkName: "components/elements-work-with-us-form" */).then(c => wrapFunctional(c.default || c))
export const HomeAboutUs = () => import('../../components/home/AboutUs.vue' /* webpackChunkName: "components/home-about-us" */).then(c => wrapFunctional(c.default || c))
export const HomeHowDoesItWork = () => import('../../components/home/HowDoesItWork.vue' /* webpackChunkName: "components/home-how-does-it-work" */).then(c => wrapFunctional(c.default || c))
export const HomeMyAsta = () => import('../../components/home/MyAsta.vue' /* webpackChunkName: "components/home-my-asta" */).then(c => wrapFunctional(c.default || c))
export const HomeSuggestionList = () => import('../../components/home/SuggestionList.vue' /* webpackChunkName: "components/home-suggestion-list" */).then(c => wrapFunctional(c.default || c))
export const HomeTheBrand = () => import('../../components/home/TheBrand.vue' /* webpackChunkName: "components/home-the-brand" */).then(c => wrapFunctional(c.default || c))
export const HomeTheNews = () => import('../../components/home/TheNews.vue' /* webpackChunkName: "components/home-the-news" */).then(c => wrapFunctional(c.default || c))
export const HomeWorkingWith = () => import('../../components/home/WorkingWith.vue' /* webpackChunkName: "components/home-working-with" */).then(c => wrapFunctional(c.default || c))
export const LayoutsTheFooterAstagiudiziaria = () => import('../../components/layouts/TheFooterAstagiudiziaria.vue' /* webpackChunkName: "components/layouts-the-footer-astagiudiziaria" */).then(c => wrapFunctional(c.default || c))
export const LayoutsTheFooterMaster = () => import('../../components/layouts/TheFooterMaster.vue' /* webpackChunkName: "components/layouts-the-footer-master" */).then(c => wrapFunctional(c.default || c))
export const LayoutsTheFooterRoma = () => import('../../components/layouts/TheFooterRoma.vue' /* webpackChunkName: "components/layouts-the-footer-roma" */).then(c => wrapFunctional(c.default || c))
export const LayoutsTheHeaderAstagiudiziaria = () => import('../../components/layouts/TheHeaderAstagiudiziaria.vue' /* webpackChunkName: "components/layouts-the-header-astagiudiziaria" */).then(c => wrapFunctional(c.default || c))
export const LayoutsTheHeaderMaster = () => import('../../components/layouts/TheHeaderMaster.vue' /* webpackChunkName: "components/layouts-the-header-master" */).then(c => wrapFunctional(c.default || c))
export const LayoutsTheHeaderRoma = () => import('../../components/layouts/TheHeaderRoma.vue' /* webpackChunkName: "components/layouts-the-header-roma" */).then(c => wrapFunctional(c.default || c))
export const ModulesBANNERRICERCA = () => import('../../components/modules/BANNERRICERCA.vue' /* webpackChunkName: "components/modules-b-a-n-n-e-r-r-i-c-e-r-c-a" */).then(c => wrapFunctional(c.default || c))
export const ModulesCALENDARIO = () => import('../../components/modules/CALENDARIO.vue' /* webpackChunkName: "components/modules-c-a-l-e-n-d-a-r-i-o" */).then(c => wrapFunctional(c.default || c))
export const ModulesPOPUP = () => import('../../components/modules/POPUP.vue' /* webpackChunkName: "components/modules-p-o-p-u-p" */).then(c => wrapFunctional(c.default || c))
export const ModulesPOPUPASTA = () => import('../../components/modules/POPUPASTA.vue' /* webpackChunkName: "components/modules-p-o-p-u-p-a-s-t-a" */).then(c => wrapFunctional(c.default || c))
export const ModulesTheBanner = () => import('../../components/modules/TheBanner.vue' /* webpackChunkName: "components/modules-the-banner" */).then(c => wrapFunctional(c.default || c))
export const ModulesTheComponent = () => import('../../components/modules/TheComponent.vue' /* webpackChunkName: "components/modules-the-component" */).then(c => wrapFunctional(c.default || c))
export const ModulesTheDefault = () => import('../../components/modules/TheDefault.vue' /* webpackChunkName: "components/modules-the-default" */).then(c => wrapFunctional(c.default || c))
export const ModulesULTIMEINSERZIONI = () => import('../../components/modules/ULTIMEINSERZIONI.vue' /* webpackChunkName: "components/modules-u-l-t-i-m-e-i-n-s-e-r-z-i-o-n-i" */).then(c => wrapFunctional(c.default || c))
export const MyastaLogOut = () => import('../../components/myasta/LogOut.vue' /* webpackChunkName: "components/myasta-log-out" */).then(c => wrapFunctional(c.default || c))
export const MyastaSavedSearches = () => import('../../components/myasta/SavedSearches.vue' /* webpackChunkName: "components/myasta-saved-searches" */).then(c => wrapFunctional(c.default || c))
export const MyastaStaticFilterSummary = () => import('../../components/myasta/StaticFilterSummary.vue' /* webpackChunkName: "components/myasta-static-filter-summary" */).then(c => wrapFunctional(c.default || c))
export const MyastaTheDocuments = () => import('../../components/myasta/TheDocuments.vue' /* webpackChunkName: "components/myasta-the-documents" */).then(c => wrapFunctional(c.default || c))
export const MyastaTheNotifications = () => import('../../components/myasta/TheNotifications.vue' /* webpackChunkName: "components/myasta-the-notifications" */).then(c => wrapFunctional(c.default || c))
export const MyastaTheProfile = () => import('../../components/myasta/TheProfile.vue' /* webpackChunkName: "components/myasta-the-profile" */).then(c => wrapFunctional(c.default || c))
export const MyastaTheWishlist = () => import('../../components/myasta/TheWishlist.vue' /* webpackChunkName: "components/myasta-the-wishlist" */).then(c => wrapFunctional(c.default || c))
export const ResultsCardSummary = () => import('../../components/results/CardSummary.vue' /* webpackChunkName: "components/results-card-summary" */).then(c => wrapFunctional(c.default || c))
export const ResultsTheCard = () => import('../../components/results/TheCard.vue' /* webpackChunkName: "components/results-the-card" */).then(c => wrapFunctional(c.default || c))
export const ResultsTheList = () => import('../../components/results/TheList.vue' /* webpackChunkName: "components/results-the-list" */).then(c => wrapFunctional(c.default || c))
export const ResultsTheListCard = () => import('../../components/results/TheListCard.vue' /* webpackChunkName: "components/results-the-list-card" */).then(c => wrapFunctional(c.default || c))
export const ResultsThePaginator = () => import('../../components/results/ThePaginator.vue' /* webpackChunkName: "components/results-the-paginator" */).then(c => wrapFunctional(c.default || c))
export const ShowTheAsta = () => import('../../components/show/TheAsta.vue' /* webpackChunkName: "components/show-the-asta" */).then(c => wrapFunctional(c.default || c))
export const ShowTheBreadcrumbs = () => import('../../components/show/TheBreadcrumbs.vue' /* webpackChunkName: "components/show-the-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const ShowTheCategoryTipology = () => import('../../components/show/TheCategoryTipology.vue' /* webpackChunkName: "components/show-the-category-tipology" */).then(c => wrapFunctional(c.default || c))
export const ShowTheCorrelated = () => import('../../components/show/TheCorrelated.vue' /* webpackChunkName: "components/show-the-correlated" */).then(c => wrapFunctional(c.default || c))
export const ShowTheDetails = () => import('../../components/show/TheDetails.vue' /* webpackChunkName: "components/show-the-details" */).then(c => wrapFunctional(c.default || c))
export const ShowTheGallery = () => import('../../components/show/TheGallery.vue' /* webpackChunkName: "components/show-the-gallery" */).then(c => wrapFunctional(c.default || c))
export const ShowTheMedia = () => import('../../components/show/TheMedia.vue' /* webpackChunkName: "components/show-the-media" */).then(c => wrapFunctional(c.default || c))
export const ShowTheStatus = () => import('../../components/show/TheStatus.vue' /* webpackChunkName: "components/show-the-status" */).then(c => wrapFunctional(c.default || c))
export const ShowTheTabs = () => import('../../components/show/TheTabs.vue' /* webpackChunkName: "components/show-the-tabs" */).then(c => wrapFunctional(c.default || c))
export const SkeletonMyAstaSavedSearch = () => import('../../components/skeleton/MyAstaSavedSearch.vue' /* webpackChunkName: "components/skeleton-my-asta-saved-search" */).then(c => wrapFunctional(c.default || c))
export const SkeletonTheCard = () => import('../../components/skeleton/TheCard.vue' /* webpackChunkName: "components/skeleton-the-card" */).then(c => wrapFunctional(c.default || c))
export const SkeletonTheInserzione = () => import('../../components/skeleton/TheInserzione.vue' /* webpackChunkName: "components/skeleton-the-inserzione" */).then(c => wrapFunctional(c.default || c))
export const SkeletonTheListCard = () => import('../../components/skeleton/TheListCard.vue' /* webpackChunkName: "components/skeleton-the-list-card" */).then(c => wrapFunctional(c.default || c))
export const StaticTheBreadcrumbs = () => import('../../components/static/TheBreadcrumbs.vue' /* webpackChunkName: "components/static-the-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const StaticTheMoreDetailsBtn = () => import('../../components/static/TheMoreDetailsBtn.vue' /* webpackChunkName: "components/static-the-more-details-btn" */).then(c => wrapFunctional(c.default || c))
export const StaticTheMoreDetailsBtnPush = () => import('../../components/static/TheMoreDetailsBtnPush.vue' /* webpackChunkName: "components/static-the-more-details-btn-push" */).then(c => wrapFunctional(c.default || c))
export const StaticTheSeparator = () => import('../../components/static/TheSeparator.vue' /* webpackChunkName: "components/static-the-separator" */).then(c => wrapFunctional(c.default || c))
export const CoreErrorsAlertAction = () => import('../../components/core/errors/AlertAction.vue' /* webpackChunkName: "components/core-errors-alert-action" */).then(c => wrapFunctional(c.default || c))
export const CoreSearchTheFilterButton = () => import('../../components/core/search/TheFilterButton.vue' /* webpackChunkName: "components/core-search-the-filter-button" */).then(c => wrapFunctional(c.default || c))
export const CoreSearchTheFilterSummary = () => import('../../components/core/search/TheFilterSummary.vue' /* webpackChunkName: "components/core-search-the-filter-summary" */).then(c => wrapFunctional(c.default || c))
export const CoreSearchTheMap = () => import('../../components/core/search/TheMap.vue' /* webpackChunkName: "components/core-search-the-map" */).then(c => wrapFunctional(c.default || c))
export const CoreSearchTheMapView = () => import('../../components/core/search/TheMapView.vue' /* webpackChunkName: "components/core-search-the-map-view" */).then(c => wrapFunctional(c.default || c))
export const CoreSearchTheModal = () => import('../../components/core/search/TheModal.vue' /* webpackChunkName: "components/core-search-the-modal" */).then(c => wrapFunctional(c.default || c))
export const CoreSearchTheModalFooter = () => import('../../components/core/search/TheModalFooter.vue' /* webpackChunkName: "components/core-search-the-modal-footer" */).then(c => wrapFunctional(c.default || c))
export const CoreSearchTheModalHeader = () => import('../../components/core/search/TheModalHeader.vue' /* webpackChunkName: "components/core-search-the-modal-header" */).then(c => wrapFunctional(c.default || c))
export const CoreSearchTheSaveSearchButton = () => import('../../components/core/search/TheSaveSearchButton.vue' /* webpackChunkName: "components/core-search-the-save-search-button" */).then(c => wrapFunctional(c.default || c))
export const CoreSearchTheSuggestionButton = () => import('../../components/core/search/TheSuggestionButton.vue' /* webpackChunkName: "components/core-search-the-suggestion-button" */).then(c => wrapFunctional(c.default || c))
export const CoreSearchTheText = () => import('../../components/core/search/TheText.vue' /* webpackChunkName: "components/core-search-the-text" */).then(c => wrapFunctional(c.default || c))
export const CoreSearchTheTextDropdown = () => import('../../components/core/search/TheTextDropdown.vue' /* webpackChunkName: "components/core-search-the-text-dropdown" */).then(c => wrapFunctional(c.default || c))
export const CoreSelectTheDateButton = () => import('../../components/core/select/TheDateButton.vue' /* webpackChunkName: "components/core-select-the-date-button" */).then(c => wrapFunctional(c.default || c))
export const CoreSelectTheDateModal = () => import('../../components/core/select/TheDateModal.vue' /* webpackChunkName: "components/core-select-the-date-modal" */).then(c => wrapFunctional(c.default || c))
export const CoreSelectTheMultiSelect = () => import('../../components/core/select/TheMultiSelect.vue' /* webpackChunkName: "components/core-select-the-multi-select" */).then(c => wrapFunctional(c.default || c))
export const CoreSelectTheRange = () => import('../../components/core/select/TheRange.vue' /* webpackChunkName: "components/core-select-the-range" */).then(c => wrapFunctional(c.default || c))
export const CoreSelectTheRangeModal = () => import('../../components/core/select/TheRangeModal.vue' /* webpackChunkName: "components/core-select-the-range-modal" */).then(c => wrapFunctional(c.default || c))
export const CoreSelectTheSelect = () => import('../../components/core/select/TheSelect.vue' /* webpackChunkName: "components/core-select-the-select" */).then(c => wrapFunctional(c.default || c))
export const CoreSortTheModal = () => import('../../components/core/sort/TheModal.vue' /* webpackChunkName: "components/core-sort-the-modal" */).then(c => wrapFunctional(c.default || c))
export const CoreSortTheModalHeader = () => import('../../components/core/sort/TheModalHeader.vue' /* webpackChunkName: "components/core-sort-the-modal-header" */).then(c => wrapFunctional(c.default || c))
export const CoreSortTheSort = () => import('../../components/core/sort/TheSort.vue' /* webpackChunkName: "components/core-sort-the-sort" */).then(c => wrapFunctional(c.default || c))
export const ElementsLightboxCloseIcon = () => import('../../components/elements/lightbox/CloseIcon.vue' /* webpackChunkName: "components/elements-lightbox-close-icon" */).then(c => wrapFunctional(c.default || c))
export const ElementsLightboxLeftArrowIcon = () => import('../../components/elements/lightbox/LeftArrowIcon.vue' /* webpackChunkName: "components/elements-lightbox-left-arrow-icon" */).then(c => wrapFunctional(c.default || c))
export const ElementsLightboxRightArrowIcon = () => import('../../components/elements/lightbox/RightArrowIcon.vue' /* webpackChunkName: "components/elements-lightbox-right-arrow-icon" */).then(c => wrapFunctional(c.default || c))
export const ElementsLightboxTheWrapper = () => import('../../components/elements/lightbox/TheWrapper.vue' /* webpackChunkName: "components/elements-lightbox-the-wrapper" */).then(c => wrapFunctional(c.default || c))
export const ElementsLightboxVideoIcon = () => import('../../components/elements/lightbox/VideoIcon.vue' /* webpackChunkName: "components/elements-lightbox-video-icon" */).then(c => wrapFunctional(c.default || c))
export const HomeAboutTheBox = () => import('../../components/home/about/TheBox.vue' /* webpackChunkName: "components/home-about-the-box" */).then(c => wrapFunctional(c.default || c))
export const HomeIvgAboutUs = () => import('../../components/home/ivg/AboutUs.vue' /* webpackChunkName: "components/home-ivg-about-us" */).then(c => wrapFunctional(c.default || c))
export const HomeIvgMainImage = () => import('../../components/home/ivg/MainImage.vue' /* webpackChunkName: "components/home-ivg-main-image" */).then(c => wrapFunctional(c.default || c))
export const HomeIvgSuggestionList = () => import('../../components/home/ivg/SuggestionList.vue' /* webpackChunkName: "components/home-ivg-suggestion-list" */).then(c => wrapFunctional(c.default || c))
export const HomeIvgTheBankruptcy = () => import('../../components/home/ivg/TheBankruptcy.vue' /* webpackChunkName: "components/home-ivg-the-bankruptcy" */).then(c => wrapFunctional(c.default || c))
export const HomeIvgTheCategories = () => import('../../components/home/ivg/TheCategories.vue' /* webpackChunkName: "components/home-ivg-the-categories" */).then(c => wrapFunctional(c.default || c))
export const HomeIvgTheCategory = () => import('../../components/home/ivg/TheCategory.vue' /* webpackChunkName: "components/home-ivg-the-category" */).then(c => wrapFunctional(c.default || c))
export const HomeIvgTheSkeleton = () => import('../../components/home/ivg/TheSkeleton.vue' /* webpackChunkName: "components/home-ivg-the-skeleton" */).then(c => wrapFunctional(c.default || c))
export const HomeSuggestionsSuggestionCard = () => import('../../components/home/suggestions/SuggestionCard.vue' /* webpackChunkName: "components/home-suggestions-suggestion-card" */).then(c => wrapFunctional(c.default || c))
export const LayoutsHeaderTheA = () => import('../../components/layouts/header/TheA.vue' /* webpackChunkName: "components/layouts-header-the-a" */).then(c => wrapFunctional(c.default || c))
export const LayoutsHeaderTheImage = () => import('../../components/layouts/header/TheImage.vue' /* webpackChunkName: "components/layouts-header-the-image" */).then(c => wrapFunctional(c.default || c))
export const LayoutsHeaderTheLinkText = () => import('../../components/layouts/header/TheLinkText.vue' /* webpackChunkName: "components/layouts-header-the-link-text" */).then(c => wrapFunctional(c.default || c))
export const LayoutsHeaderTheLogo = () => import('../../components/layouts/header/TheLogo.vue' /* webpackChunkName: "components/layouts-header-the-logo" */).then(c => wrapFunctional(c.default || c))
export const LayoutsHeaderTheMenu = () => import('../../components/layouts/header/TheMenu.vue' /* webpackChunkName: "components/layouts-header-the-menu" */).then(c => wrapFunctional(c.default || c))
export const LayoutsHeaderTheMenuDropdown = () => import('../../components/layouts/header/TheMenuDropdown.vue' /* webpackChunkName: "components/layouts-header-the-menu-dropdown" */).then(c => wrapFunctional(c.default || c))
export const ResultsCardButtonFavourite = () => import('../../components/results/card/ButtonFavourite.vue' /* webpackChunkName: "components/results-card-button-favourite" */).then(c => wrapFunctional(c.default || c))
export const ResultsCardButtonShare = () => import('../../components/results/card/ButtonShare.vue' /* webpackChunkName: "components/results-card-button-share" */).then(c => wrapFunctional(c.default || c))
export const ResultsCardButtonShareModal = () => import('../../components/results/card/ButtonShareModal.vue' /* webpackChunkName: "components/results-card-button-share-modal" */).then(c => wrapFunctional(c.default || c))
export const ResultsCardFooterData = () => import('../../components/results/card/FooterData.vue' /* webpackChunkName: "components/results-card-footer-data" */).then(c => wrapFunctional(c.default || c))
export const ResultsCardFooterSingleItem = () => import('../../components/results/card/FooterSingleItem.vue' /* webpackChunkName: "components/results-card-footer-single-item" */).then(c => wrapFunctional(c.default || c))
export const ResultsCardTheCategoryTipology = () => import('../../components/results/card/TheCategoryTipology.vue' /* webpackChunkName: "components/results-card-the-category-tipology" */).then(c => wrapFunctional(c.default || c))
export const ResultsCardTheDate = () => import('../../components/results/card/TheDate.vue' /* webpackChunkName: "components/results-card-the-date" */).then(c => wrapFunctional(c.default || c))
export const ResultsCardTheListSwiper = () => import('../../components/results/card/TheListSwiper.vue' /* webpackChunkName: "components/results-card-the-list-swiper" */).then(c => wrapFunctional(c.default || c))
export const ResultsCardThePrice = () => import('../../components/results/card/ThePrice.vue' /* webpackChunkName: "components/results-card-the-price" */).then(c => wrapFunctional(c.default || c))
export const ResultsCardTheSwiper = () => import('../../components/results/card/TheSwiper.vue' /* webpackChunkName: "components/results-card-the-swiper" */).then(c => wrapFunctional(c.default || c))
export const ResultsCardTheTitle = () => import('../../components/results/card/TheTitle.vue' /* webpackChunkName: "components/results-card-the-title" */).then(c => wrapFunctional(c.default || c))
export const ResultsMapTheControls = () => import('../../components/results/map/TheControls.vue' /* webpackChunkName: "components/results-map-the-controls" */).then(c => wrapFunctional(c.default || c))
export const ResultsMapTheList = () => import('../../components/results/map/TheList.vue' /* webpackChunkName: "components/results-map-the-list" */).then(c => wrapFunctional(c.default || c))
export const ResultsMapTheMap = () => import('../../components/results/map/TheMap.vue' /* webpackChunkName: "components/results-map-the-map" */).then(c => wrapFunctional(c.default || c))
export const ResultsMapTheView = () => import('../../components/results/map/TheView.vue' /* webpackChunkName: "components/results-map-the-view" */).then(c => wrapFunctional(c.default || c))
export const ShowAstaBasePrice = () => import('../../components/show/asta/BasePrice.vue' /* webpackChunkName: "components/show-asta-base-price" */).then(c => wrapFunctional(c.default || c))
export const ShowAstaCardActions = () => import('../../components/show/asta/CardActions.vue' /* webpackChunkName: "components/show-asta-card-actions" */).then(c => wrapFunctional(c.default || c))
export const ShowAstaLabelValue = () => import('../../components/show/asta/LabelValue.vue' /* webpackChunkName: "components/show-asta-label-value" */).then(c => wrapFunctional(c.default || c))
export const ShowAstaMinOffer = () => import('../../components/show/asta/MinOffer.vue' /* webpackChunkName: "components/show-asta-min-offer" */).then(c => wrapFunctional(c.default || c))
export const ShowAstaTheLabels = () => import('../../components/show/asta/TheLabels.vue' /* webpackChunkName: "components/show-asta-the-labels" */).then(c => wrapFunctional(c.default || c))
export const ShowAstaThePrint = () => import('../../components/show/asta/ThePrint.vue' /* webpackChunkName: "components/show-asta-the-print" */).then(c => wrapFunctional(c.default || c))
export const ShowDetailsTheAttachmentBtn = () => import('../../components/show/details/TheAttachmentBtn.vue' /* webpackChunkName: "components/show-details-the-attachment-btn" */).then(c => wrapFunctional(c.default || c))
export const ShowDetailsTheDescription = () => import('../../components/show/details/TheDescription.vue' /* webpackChunkName: "components/show-details-the-description" */).then(c => wrapFunctional(c.default || c))
export const ShowDetailsTheDocuments = () => import('../../components/show/details/TheDocuments.vue' /* webpackChunkName: "components/show-details-the-documents" */).then(c => wrapFunctional(c.default || c))
export const ShowFormsTheBooking = () => import('../../components/show/forms/TheBooking.vue' /* webpackChunkName: "components/show-forms-the-booking" */).then(c => wrapFunctional(c.default || c))
export const ShowFormsTheContact = () => import('../../components/show/forms/TheContact.vue' /* webpackChunkName: "components/show-forms-the-contact" */).then(c => wrapFunctional(c.default || c))
export const ShowFormsThePvpLink = () => import('../../components/show/forms/ThePvpLink.vue' /* webpackChunkName: "components/show-forms-the-pvp-link" */).then(c => wrapFunctional(c.default || c))
export const ShowGalleryFiveAndMore = () => import('../../components/show/gallery/FiveAndMore.vue' /* webpackChunkName: "components/show-gallery-five-and-more" */).then(c => wrapFunctional(c.default || c))
export const ShowGalleryImageWrapper = () => import('../../components/show/gallery/ImageWrapper.vue' /* webpackChunkName: "components/show-gallery-image-wrapper" */).then(c => wrapFunctional(c.default || c))
export const ShowGalleryMultipleImageWrapper = () => import('../../components/show/gallery/MultipleImageWrapper.vue' /* webpackChunkName: "components/show-gallery-multiple-image-wrapper" */).then(c => wrapFunctional(c.default || c))
export const ShowGalleryOnlyFour = () => import('../../components/show/gallery/OnlyFour.vue' /* webpackChunkName: "components/show-gallery-only-four" */).then(c => wrapFunctional(c.default || c))
export const ShowGalleryOnlyOne = () => import('../../components/show/gallery/OnlyOne.vue' /* webpackChunkName: "components/show-gallery-only-one" */).then(c => wrapFunctional(c.default || c))
export const ShowGalleryOnlyThree = () => import('../../components/show/gallery/OnlyThree.vue' /* webpackChunkName: "components/show-gallery-only-three" */).then(c => wrapFunctional(c.default || c))
export const ShowGalleryOnlyTwo = () => import('../../components/show/gallery/OnlyTwo.vue' /* webpackChunkName: "components/show-gallery-only-two" */).then(c => wrapFunctional(c.default || c))
export const ShowGalleryTheSwiper = () => import('../../components/show/gallery/TheSwiper.vue' /* webpackChunkName: "components/show-gallery-the-swiper" */).then(c => wrapFunctional(c.default || c))
export const ShowMediaTheNewTour = () => import('../../components/show/media/TheNewTour.vue' /* webpackChunkName: "components/show-media-the-new-tour" */).then(c => wrapFunctional(c.default || c))
export const ShowMediaTheTour = () => import('../../components/show/media/TheTour.vue' /* webpackChunkName: "components/show-media-the-tour" */).then(c => wrapFunctional(c.default || c))
export const ShowMediaTheVideo = () => import('../../components/show/media/TheVideo.vue' /* webpackChunkName: "components/show-media-the-video" */).then(c => wrapFunctional(c.default || c))
export const ShowTabsDettaglioLotto = () => import('../../components/show/tabs/DettaglioLotto.vue' /* webpackChunkName: "components/show-tabs-dettaglio-lotto" */).then(c => wrapFunctional(c.default || c))
export const ShowTabsInformazioniVendita = () => import('../../components/show/tabs/InformazioniVendita.vue' /* webpackChunkName: "components/show-tabs-informazioni-vendita" */).then(c => wrapFunctional(c.default || c))
export const ShowTabsLabelValue = () => import('../../components/show/tabs/LabelValue.vue' /* webpackChunkName: "components/show-tabs-label-value" */).then(c => wrapFunctional(c.default || c))
export const ShowTabsModalitaPartecipazione = () => import('../../components/show/tabs/ModalitaPartecipazione.vue' /* webpackChunkName: "components/show-tabs-modalita-partecipazione" */).then(c => wrapFunctional(c.default || c))
export const ShowTabsPvp = () => import('../../components/show/tabs/Pvp.vue' /* webpackChunkName: "components/show-tabs-pvp" */).then(c => wrapFunctional(c.default || c))
export const CoreSearchFiltersTheAlert = () => import('../../components/core/search/filters/TheAlert.vue' /* webpackChunkName: "components/core-search-filters-the-alert" */).then(c => wrapFunctional(c.default || c))
export const CoreSearchFiltersTheBanned = () => import('../../components/core/search/filters/TheBanned.vue' /* webpackChunkName: "components/core-search-filters-the-banned" */).then(c => wrapFunctional(c.default || c))
export const CoreSearchFiltersTheCategory = () => import('../../components/core/search/filters/TheCategory.vue' /* webpackChunkName: "components/core-search-filters-the-category" */).then(c => wrapFunctional(c.default || c))
export const CoreSearchFiltersTheCity = () => import('../../components/core/search/filters/TheCity.vue' /* webpackChunkName: "components/core-search-filters-the-city" */).then(c => wrapFunctional(c.default || c))
export const CoreSearchFiltersTheCurrent = () => import('../../components/core/search/filters/TheCurrent.vue' /* webpackChunkName: "components/core-search-filters-the-current" */).then(c => wrapFunctional(c.default || c))
export const CoreSearchFiltersTheExpiringInDays = () => import('../../components/core/search/filters/TheExpiringInDays.vue' /* webpackChunkName: "components/core-search-filters-the-expiring-in-days" */).then(c => wrapFunctional(c.default || c))
export const CoreSearchFiltersTheGenre = () => import('../../components/core/search/filters/TheGenre.vue' /* webpackChunkName: "components/core-search-filters-the-genre" */).then(c => wrapFunctional(c.default || c))
export const CoreSearchFiltersTheHasGaraOnline = () => import('../../components/core/search/filters/TheHasGaraOnline.vue' /* webpackChunkName: "components/core-search-filters-the-has-gara-online" */).then(c => wrapFunctional(c.default || c))
export const CoreSearchFiltersTheIvgNumber = () => import('../../components/core/search/filters/TheIvgNumber.vue' /* webpackChunkName: "components/core-search-filters-the-ivg-number" */).then(c => wrapFunctional(c.default || c))
export const CoreSearchFiltersTheLatestDays = () => import('../../components/core/search/filters/TheLatestDays.vue' /* webpackChunkName: "components/core-search-filters-the-latest-days" */).then(c => wrapFunctional(c.default || c))
export const CoreSearchFiltersThePriceModal = () => import('../../components/core/search/filters/ThePriceModal.vue' /* webpackChunkName: "components/core-search-filters-the-price-modal" */).then(c => wrapFunctional(c.default || c))
export const CoreSearchFiltersTheProvince = () => import('../../components/core/search/filters/TheProvince.vue' /* webpackChunkName: "components/core-search-filters-the-province" */).then(c => wrapFunctional(c.default || c))
export const CoreSearchFiltersTheSelectControparte = () => import('../../components/core/search/filters/TheSelectControparte.vue' /* webpackChunkName: "components/core-search-filters-the-select-controparte" */).then(c => wrapFunctional(c.default || c))
export const CoreSearchFiltersTheSelectIvg = () => import('../../components/core/search/filters/TheSelectIvg.vue' /* webpackChunkName: "components/core-search-filters-the-select-ivg" */).then(c => wrapFunctional(c.default || c))
export const CoreSearchFiltersTheSelectTipoProcedura = () => import('../../components/core/search/filters/TheSelectTipoProcedura.vue' /* webpackChunkName: "components/core-search-filters-the-select-tipo-procedura" */).then(c => wrapFunctional(c.default || c))
export const CoreSearchFiltersTheSoldInTheLastTwoWeeks = () => import('../../components/core/search/filters/TheSoldInTheLastTwoWeeks.vue' /* webpackChunkName: "components/core-search-filters-the-sold-in-the-last-two-weeks" */).then(c => wrapFunctional(c.default || c))
export const CoreSearchFiltersTheStatus = () => import('../../components/core/search/filters/TheStatus.vue' /* webpackChunkName: "components/core-search-filters-the-status" */).then(c => wrapFunctional(c.default || c))
export const CoreSearchFiltersTheSubcategory = () => import('../../components/core/search/filters/TheSubcategory.vue' /* webpackChunkName: "components/core-search-filters-the-subcategory" */).then(c => wrapFunctional(c.default || c))
export const CoreSearchFiltersTheSwitch = () => import('../../components/core/search/filters/TheSwitch.vue' /* webpackChunkName: "components/core-search-filters-the-switch" */).then(c => wrapFunctional(c.default || c))
export const CoreSearchFiltersTheText = () => import('../../components/core/search/filters/TheText.vue' /* webpackChunkName: "components/core-search-filters-the-text" */).then(c => wrapFunctional(c.default || c))
export const CoreSearchMapTheControls = () => import('../../components/core/search/map/TheControls.vue' /* webpackChunkName: "components/core-search-map-the-controls" */).then(c => wrapFunctional(c.default || c))
export const CoreSearchMapTheHeader = () => import('../../components/core/search/map/TheHeader.vue' /* webpackChunkName: "components/core-search-map-the-header" */).then(c => wrapFunctional(c.default || c))
export const ResultsCardPriceFormattedPrice = () => import('../../components/results/card/price/FormattedPrice.vue' /* webpackChunkName: "components/results-card-price-formatted-price" */).then(c => wrapFunctional(c.default || c))
export const ResultsCardPriceMinimumOffer = () => import('../../components/results/card/price/MinimumOffer.vue' /* webpackChunkName: "components/results-card-price-minimum-offer" */).then(c => wrapFunctional(c.default || c))
export const ResultsCardPriceTheDefault = () => import('../../components/results/card/price/TheDefault.vue' /* webpackChunkName: "components/results-card-price-the-default" */).then(c => wrapFunctional(c.default || c))
export const ShowTabsDettaglioLottoSafariMap = () => import('../../components/show/tabs/dettaglioLotto/SafariMap.vue' /* webpackChunkName: "components/show-tabs-dettaglio-lotto-safari-map" */).then(c => wrapFunctional(c.default || c))
export const ShowTabsDettaglioLottoTheBeni = () => import('../../components/show/tabs/dettaglioLotto/TheBeni.vue' /* webpackChunkName: "components/show-tabs-dettaglio-lotto-the-beni" */).then(c => wrapFunctional(c.default || c))
export const ShowTabsDettaglioLottoTheLotto = () => import('../../components/show/tabs/dettaglioLotto/TheLotto.vue' /* webpackChunkName: "components/show-tabs-dettaglio-lotto-the-lotto" */).then(c => wrapFunctional(c.default || c))
export const ShowTabsDettaglioLottoTheMap = () => import('../../components/show/tabs/dettaglioLotto/TheMap.vue' /* webpackChunkName: "components/show-tabs-dettaglio-lotto-the-map" */).then(c => wrapFunctional(c.default || c))
export const ShowTabsDettaglioPvpGoodsData = () => import('../../components/show/tabs/dettaglioPvp/GoodsData.vue' /* webpackChunkName: "components/show-tabs-dettaglio-pvp-goods-data" */).then(c => wrapFunctional(c.default || c))
export const ShowTabsDettaglioPvpLotData = () => import('../../components/show/tabs/dettaglioPvp/LotData.vue' /* webpackChunkName: "components/show-tabs-dettaglio-pvp-lot-data" */).then(c => wrapFunctional(c.default || c))
export const ShowTabsDettaglioPvpSingleData = () => import('../../components/show/tabs/dettaglioPvp/SingleData.vue' /* webpackChunkName: "components/show-tabs-dettaglio-pvp-single-data" */).then(c => wrapFunctional(c.default || c))
export const ShowTabsDettaglioStoricoTheChart = () => import('../../components/show/tabs/dettaglioStorico/TheChart.vue' /* webpackChunkName: "components/show-tabs-dettaglio-storico-the-chart" */).then(c => wrapFunctional(c.default || c))
export const ShowTabsDettaglioStoricoTheTable = () => import('../../components/show/tabs/dettaglioStorico/TheTable.vue' /* webpackChunkName: "components/show-tabs-dettaglio-storico-the-table" */).then(c => wrapFunctional(c.default || c))
export const ShowTabsDettaglioVenditaDetailClaim = () => import('../../components/show/tabs/dettaglioVendita/DetailClaim.vue' /* webpackChunkName: "components/show-tabs-dettaglio-vendita-detail-claim" */).then(c => wrapFunctional(c.default || c))
export const ShowTabsDettaglioVenditaGroupCondizioniVendita = () => import('../../components/show/tabs/dettaglioVendita/GroupCondizioniVendita.vue' /* webpackChunkName: "components/show-tabs-dettaglio-vendita-group-condizioni-vendita" */).then(c => wrapFunctional(c.default || c))
export const ShowTabsDettaglioVenditaGroupDatiVendita = () => import('../../components/show/tabs/dettaglioVendita/GroupDatiVendita.vue' /* webpackChunkName: "components/show-tabs-dettaglio-vendita-group-dati-vendita" */).then(c => wrapFunctional(c.default || c))
export const ShowTabsDettaglioVenditaGroupGeneric = () => import('../../components/show/tabs/dettaglioVendita/GroupGeneric.vue' /* webpackChunkName: "components/show-tabs-dettaglio-vendita-group-generic" */).then(c => wrapFunctional(c.default || c))
export const ShowTabsDettaglioVenditaGroupSoggetti = () => import('../../components/show/tabs/dettaglioVendita/GroupSoggetti.vue' /* webpackChunkName: "components/show-tabs-dettaglio-vendita-group-soggetti" */).then(c => wrapFunctional(c.default || c))
export const ShowTabsDettaglioVenditaLuogoVendita = () => import('../../components/show/tabs/dettaglioVendita/LuogoVendita.vue' /* webpackChunkName: "components/show-tabs-dettaglio-vendita-luogo-vendita" */).then(c => wrapFunctional(c.default || c))
export const ShowTabsDettaglioVenditaPrintOrX = () => import('../../components/show/tabs/dettaglioVendita/PrintOrX.vue' /* webpackChunkName: "components/show-tabs-dettaglio-vendita-print-or-x" */).then(c => wrapFunctional(c.default || c))
export const ShowTabsDettaglioPvpGoodsBeneAzienda = () => import('../../components/show/tabs/dettaglioPvp/goods/BeneAzienda.vue' /* webpackChunkName: "components/show-tabs-dettaglio-pvp-goods-bene-azienda" */).then(c => wrapFunctional(c.default || c))
export const ShowTabsDettaglioPvpGoodsBeneImmobile = () => import('../../components/show/tabs/dettaglioPvp/goods/BeneImmobile.vue' /* webpackChunkName: "components/show-tabs-dettaglio-pvp-goods-bene-immobile" */).then(c => wrapFunctional(c.default || c))
export const ShowTabsDettaglioPvpGoodsBeneMobile = () => import('../../components/show/tabs/dettaglioPvp/goods/BeneMobile.vue' /* webpackChunkName: "components/show-tabs-dettaglio-pvp-goods-bene-mobile" */).then(c => wrapFunctional(c.default || c))
export const ShowTabsDettaglioPvpGoodsBeneUbicazione = () => import('../../components/show/tabs/dettaglioPvp/goods/BeneUbicazione.vue' /* webpackChunkName: "components/show-tabs-dettaglio-pvp-goods-bene-ubicazione" */).then(c => wrapFunctional(c.default || c))
export const ShowTabsDettaglioPvpGoodsImmobiliDatiCatastali = () => import('../../components/show/tabs/dettaglioPvp/goods/immobili/DatiCatastali.vue' /* webpackChunkName: "components/show-tabs-dettaglio-pvp-goods-immobili-dati-catastali" */).then(c => wrapFunctional(c.default || c))
export const ShowTabsDettaglioPvpGoodsImmobiliDatiDenuncia = () => import('../../components/show/tabs/dettaglioPvp/goods/immobili/DatiDenuncia.vue' /* webpackChunkName: "components/show-tabs-dettaglio-pvp-goods-immobili-dati-denuncia" */).then(c => wrapFunctional(c.default || c))
export const ShowTabsDettaglioPvpGoodsMobiliLuogoRitiro = () => import('../../components/show/tabs/dettaglioPvp/goods/mobili/LuogoRitiro.vue' /* webpackChunkName: "components/show-tabs-dettaglio-pvp-goods-mobili-luogo-ritiro" */).then(c => wrapFunctional(c.default || c))
export const ShowTabsDettaglioPvpGoodsMobiliLuogoVisione = () => import('../../components/show/tabs/dettaglioPvp/goods/mobili/LuogoVisione.vue' /* webpackChunkName: "components/show-tabs-dettaglio-pvp-goods-mobili-luogo-visione" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
