import { render, staticRenderFns } from "./inserzione.vue?vue&type=template&id=67dd108e"
import script from "./inserzione.vue?vue&type=script&lang=ts"
export * from "./inserzione.vue?vue&type=script&lang=ts"
import style0 from "./inserzione.vue?vue&type=style&index=0&id=67dd108e&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoreSearchWrapper: require('/usr/src/nuxt-app/components/core/SearchWrapper.vue').default,SkeletonTheInserzione: require('/usr/src/nuxt-app/components/skeleton/TheInserzione.vue').default,CoreErrorsAlertAction: require('/usr/src/nuxt-app/components/core/errors/AlertAction.vue').default,ShowTheBreadcrumbs: require('/usr/src/nuxt-app/components/show/TheBreadcrumbs.vue').default,ShowTheGallery: require('/usr/src/nuxt-app/components/show/TheGallery.vue').default,ShowTheStatus: require('/usr/src/nuxt-app/components/show/TheStatus.vue').default,ShowTheCategoryTipology: require('/usr/src/nuxt-app/components/show/TheCategoryTipology.vue').default,ShowTabsDettaglioLottoSafariMap: require('/usr/src/nuxt-app/components/show/tabs/dettaglioLotto/SafariMap.vue').default,StaticTheSeparator: require('/usr/src/nuxt-app/components/static/TheSeparator.vue').default,ShowTheDetails: require('/usr/src/nuxt-app/components/show/TheDetails.vue').default,ShowTheAsta: require('/usr/src/nuxt-app/components/show/TheAsta.vue').default,ShowTheTabs: require('/usr/src/nuxt-app/components/show/TheTabs.vue').default,AuthLoginModal: require('/usr/src/nuxt-app/components/auth/LoginModal.vue').default,ElementsModalShare: require('/usr/src/nuxt-app/components/elements/ModalShare.vue').default,ShowTheCorrelated: require('/usr/src/nuxt-app/components/show/TheCorrelated.vue').default})
